.logo-icon{
    width: 113px;
    height: 116px;

    background: url("../../images/design-imps-logo.png");

    display: block;

    position: absolute;

    top: 10px;
    left: 10px;
}

.logo-icon{
    background-repeat: no-repeat;
    background-size: contain;
    padding: 14px 0px;
}

@media only screen and (max-width: 1150px) {
    .logo-icon{width: 100px;}
}
@media only screen and (max-width: 900px) {
    .logo-icon{width: 80px;}
}
@media only screen and (max-width: 600px) {
    .logo-icon
    {
        width: 50px;

        left: 20px;
        right: auto;
    }
}