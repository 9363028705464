.navigation-container{
    position: relative;

    margin: 0;
    padding: 0;

    -webkit-transition: background-color 0.25s, height 0.25s, margin 0.25s, width 0.25s; /* Safari */
    		transition: background-color 0.25s, height 0.25s, margin 0.25s, width 0.25s;
}

.navigation{
    position: relative;
    width: 100%;
    height: 200px;

    text-align: left;

    line-height: 60px;

    z-index: 1000;
}

.navigation .buttons{
    width: auto;
    height: 60px;

    line-height: 60px;

    vertical-align: top;
    
    /* 
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    */

    display: inline-block;

    margin: 38px 260px 0px 150px;
}

.navigation .navigation-button{
    display: inline-block;
    padding: 0 25px;

    text-decoration: none;
    color: #808F87;

    font-size: 28px;

    -webkit-transition:color 0.15s;
    transition:color 0.15s;

    overflow: hidden;

    opacity: 1.0;

    -webkit-transition: visibility 0.25s, color 0.15s, background-color 0.25s, height 0.25s, line-height 0.25s, margin 0.25s, font-size 0.25s, width 0.25s, opacity 0.25s; /* Safari */
    		transition: visibility 0.25s, color 0.15s, background-color 0.25s, height 0.25s, line-height 0.25s, margin 0.25s, font-size 0.25s, width 0.25s, opacity 0.25s;
}

.navigation .navigation-button:visited{
    color: #808F87;
}

.navigation .navigation-button:link{
    color: #808F87;
}

.navigation .navigation-button:hover{
    color: #EE452C;
}

.navigation-container .mobile-menu-button{
    visibility: hidden;

    background-repeat: no-repeat;
    background-size: cover;
}

.navigation-container .mobile-menu-button.open{
    background: url("../../images/mobile-menu-open.png");
}

.navigation-container .mobile-menu-button.closed{
    background: url("../../images/mobile-menu-close.png");
}


@media only screen and (max-width: 1150px) {
    .navigation .buttons{
        /* background-color: red; */
        margin: 35px 200px 0px 140px;
        /* margin: 38px 260px 0px 150px; */
    }

    .navigation .navigation-button{
        padding: 0 15px;
        font-size: 24px;
    }
}
@media only screen and (max-width: 900px) {
    .navigation .buttons{
        /* background-color: green; */
        margin: 25px 50px 0px 110px;
    }

    .navigation .navigation-button{
        padding: 0 8px;
        font-size: 22px;
    }
}
@media only screen and (max-width: 600px) {
    .navigation-container{
        position: fixed;
        top: 0px;
        
        z-index: 5000;
        width: 100%;

        height: 100vh;
        padding: 0;
        margin: 0;
        background: #0B0C0C;
    }

    .navigation-container.closed{
        position: absolute;
        height: 200px;

        background: rgba(0,0,0,0.0);
    }

    .navigation-container.closed > .navigation > .buttons{
        height: 0px;

        opacity: 0.0;
        visibility: hidden;
    }

    .navigation-container.closed > .navigation > .buttons > .navigation-button{
        height: 0px;

        opacity: 0.0;
        visibility: hidden;
    }

    .navigation-container .mobile-menu-button{
        visibility: visible;

        width: 50px;
        height: 50px;

        position: absolute;
        top: 20px;
        right: 20px;
    }

    .navigation .buttons{
        /* background-color: blue; */
        margin: 15px 40px;
        margin-top: 150px;

        min-width: 150px;

        width: calc(100% - 80px);
    }

    .navigation .navigation-button{
        display: block;
        width: 100%;
        margin: 2vh 0px;
        height: 15vh;

        line-height: 16vh;
        font-size: 28px;

        border: 1px solid white;
        text-align: center;
    }
}
