.banner-info-container{
    width: 100%;
    height: 100%;

    position: absolute;;

    right: 0px;
    top: 0px;

    background: url('../../../images/banner-info-darken-fade.png');

    background-size: contain;
    background-position:top right;
    background-repeat: repeat-y;
}

.banner-info-section{
    position: absolute;

    top: 100px;
    right: 0px;

    width: 372px;
}

.banner-info-game-info figure {
    position: relative;

    display: block;
    padding: 0;
    margin: 0;

    width: 372px;
    height: 296px;

    right: 0px;
    top: 0px;
}

.banner-info-game-info figure img{
    position: relative;

    width: 372px;
    height: 296px;
}

.banner-info-game-info p{
    position: relative;

    font-size: 18px;

    margin-top: 0px;

    padding: 0px 20px;

    text-align: center;
}

.banner-info-button{
    position: relative;

    font-size: 18px;

    margin-top: 30px;

    margin-left: auto;
    margin-right: auto;

    width: 120px;

    padding: 10px 20px;

    background-color: rgb(0, 0, 0, 0.25);
    border: 1px solid #FFF;

    text-align: center;
    font-weight: bold;

    -webkit-transition:color 0.25s, background-color 0.25s;
    transition:color 0.25s, background-color 0.25s;

    /*Place the button on top of the bottom overlay, to allow the user to interact with it.*/
    z-index: 100;
}

.banner-info-button:visited{
    /* color: #42B2D2; */
    background-color: rgb(0, 0, 0, 0.25);
}

.banner-info-button:link{
    /* color: #42B2D2; */
    background-color: rgb(0, 0, 0, 0.25);
}

.banner-info-button:hover{
    color: #000;
    background-color: rgb(255, 255, 255, 0.95);
}