
.dev-logs-content-section{
    margin-bottom: 25px;
}

.dev-logs-content-section h2{
    font-size: 40px;
    color: #F0E1C8;

    text-align: center;
}

.dev-logs-content-section p{
    color: #FEF8EE;
    font-size: 18px;
}

.log-intro-separator{
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.75);
    /* background-color: rgba(239, 69, 44, 1.0); */
    /* background-color: #7D2D4C; */
}

.game-selection-container{
    width: 100%;
    padding: 10px 0px;
}

.game-selection-label{
    display: inline-block;
    margin-right: 10px;
}

.game-selection-dropdown{
    display: inline-block;
    background-color: #1B1B1B;
    border: 1px solid #464646;

    color: #FFF;
    font-size: 16px;

    padding: 4px;
    margin-right: 10px;

    box-sizing: border-box;

    -webkit-transition: background-color 0.25s, border 0.25s; /* Safari */
    		transition: background-color 0.25s, border 0.25s;
}