.banner-selectors{
    position: absolute;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    top: 650px;
    left: 0px;
    
    width: 100%;

    z-index: 100;
}

.banner-selector{
    width: 20px;
    height: 20px;
    flex-basis: 20px;
    margin: 0px 5px;

    display: inline-block;

    box-sizing: border-box;

    border: 5px solid #000;

    border-radius: 100%;
    background-color: #000;

    -webkit-transition: background 0.25s, border 0.25s; /* Safari */
    		transition: background 0.25s, border 0.25s;
}

.banner-selector.active{
    background-color: #FFF;
}

.banner-selector:hover{
    background-color: #FFF;
    border-color: #FFF;
}