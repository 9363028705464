.copyright-notice{
    position: relative;
    bottom: 0px;
    left: 0px;

    width: 100%;

    text-align: center;

    font-size: 12px;

    margin-bottom: 15px;
}