#preloader{
    position: fixed;
    top: 0px;
    left: 0px;

    z-index: 5000;

    width: 100%;
    height: 100vh;

    background-color: #04000B;

    opacity: 1.0;
    visibility: visible;

    -webkit-transition: opacity 0.25s, visibility 0.25s; /* Safari */
    		transition: opacity 0.25s, visibility 0.25s;
}

#preloader.hidden{
    opacity: 0.0;
    visibility: hidden;
}

#preloader .loading-indicator{
    width: 100px;
    height: 100px;

    position: relative;

    left: calc(50% - 50px);
    top: calc(50% - 50px);

    background-image: url("../../images/preloader.png");

    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }