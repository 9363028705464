.transistion-top{
    position: absolute;
    width: 100%;
    height: 362px;

    background: url("../../images/nav_bar_top.png");

    background-size: cover;
    background-position:center center;
    background-repeat: no-repeat;
}

.transistion-bottom{
    position: absolute;
    background: url("../../images/content_area_bottom.png");
    width: 100%;
    height: 362px;

    margin: -250px 0px 0px 0px;

    background-size: cover;
    background-position:center center;
    background-repeat: no-repeat;
}
