.games-content-background-bar{
    position: fixed;

    width: 1000px;
    height: 100vh;

    top: 0px;
    left: calc(50% - 500px);

    background-color: rgba(255,255,255,0.1);

    z-index: -1000;
}

.games-content-section{
    position: relative;
    width: 1000px;
    min-height: 500px;

    top: 0px;

    margin: -100px auto 0px auto;
    padding: 120px 10px 100px 10px;

    box-sizing: border-box;
    z-index: 10;
}

.games-content-section h2{
    position: relative;
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;

    text-align: center;
}

.games-content-section p{
    font-size: 18px;
    font-weight: normal;

    text-align: center;
}

/* Ensure the content-section sizes are the same as the ContentGeneral.css' sizes */
@media only screen and (max-width: 1150px) {
    .games-content-section{
        width: 860px;
    }
}
@media only screen and (max-width: 900px) {
    .games-content-section{
        width: 580px;
    }
}
@media only screen and (max-width: 600px) {
    .games-content-section{
        width: 90%;
        min-width: 250px;

        margin: 100px auto 0px auto;
    }
} 