.contact-message-area{
    position: relative;

    width: 100%;

    background-color: #000;

    border: 1px solid #F5E9D4;

    box-sizing: border-box;

    color: #F5E9D4;
}

.contact-message-area>.contact-coulomb{
    position: relative;

    display: inline-block;

    box-sizing: border-box;
    vertical-align: top;

    width: 50%;
    margin: 0px;
    padding: 10px 0px 30px 30px;
}

.contact-coulomb>.contact-form-spacing{
    width: 100%;
    height: 65px;
}

.contact-message-area h2{
    font-size: 36px;
}

.contact-message-area p{
    margin-left: 20px;
    font-size: 18px;
}

.contact-message-area .contact-grouping{
    display: inline-block;
}

.contact-message-area .message-input-line::-webkit-input-placeholder,
.contact-message-area .message-input-text::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style: italic;
}
.contact-message-area .message-input-line::-moz-placeholder,
.contact-message-area .message-input-text::-moz-placeholder { /* Firefox 19+ */
    font-style: italic;
}
.contact-message-area .message-input-line:-ms-input-placeholder,
.contact-message-area .message-input-text:-ms-input-placeholder { /* IE 10+ */
    font-style: italic;
}
.contact-message-area .message-input-line:-moz-placeholder,
.contact-message-area .message-input-text:-moz-placeholder { /* Firefox 18- */
    font-style: italic;
}

.contact-message-area .contact-lable{
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 4px;
}

.contact-message-area .message-input-line,
.contact-message-area .message-input-text,
.contact-message-area select{
    background-color: #1B1B1B;
    border: 1px solid #464646;

    color: #FFF;
    font-size: 16px;

    padding: 4px;
    margin-right: 10px;

    box-sizing: border-box;

    -webkit-transition: background-color 0.25s, border 0.25s; /* Safari */
    		transition: background-color 0.25s, border 0.25s;
}

.contact-message-area .message-input-line.invalid,
.contact-message-area .message-input-text.invalid{
    background-color: rgb(80, 16, 2);
    border-color: rgb(148, 103, 104);
}

.contact-message-area .message-input-line{
    width: 210px;
    height: 30px;
}

.contact-message-area .message-input-text{
    width: 430px;
    height: 100px;

    resize: none;
}


/* BUTTONS */
.contact-message-area .message-buttons{
    margin: 0;
}

.contact-message-area .message-buttons .message-button {
    margin-right: 10px;
    width: 210px;
    height: 40px;

    color: #FFF;
    background-color: #000;
    
    border: 1px solid #8C8C8C;

    -webkit-transition: background-color 0.25s, color 0.25s; /* Safari */
    		transition: background-color 0.25s, color 0.25s;
}

.contact-message-area .message-buttons .message-button:hover{
    color: #000;
    background-color: #8C8C8C;
}

.contact-message-sent-result{
    position: relative;

    min-height: 400px;

    font-size: 30px;

    padding: 20px;
    box-sizing: border-box;

    text-align: center;
    object-fit: cover;
}

.contact-message-result-close{
    width: 100%;
    text-align: center;

    position: absolute;
    bottom: 0px;

    box-sizing: border-box;
    padding: 10px;

    color: #AAA;
    background-color: #222;

    -webkit-transition: background-color 0.25s, color 0.25s; /* Safari */
    		transition: background-color 0.25s, color 0.25s;
}

.contact-message-result-close:hover{
    color: #FFF;
    background-color: #555;
}

@media only screen and (max-width: 1150px) {
    .contact-message-area .message-input-text{
        width: 360px;
        height: 150px;
    }

    .contact-message-area .message-buttons .message-button {
        width: 175px;
    }
}
@media only screen and (max-width: 900px) {
    .contact-message-area>.contact-coulomb{
        display: inline-block;
        width: 100%;
    }

    .contact-coulomb>.contact-form-spacing{ height: 0px; }

    .contact-message-area .message-input-line{ width: 255px; }
    
    .contact-message-area .message-input-text{
        width: calc(100% - 30px);
        height: 150px;
    }

    .contact-message-area .message-buttons .message-button {
        width: calc(50% - 20px);
    }
}
@media only screen and (max-width: 600px) {
    .contact-message-area .message-buttons .message-button {
        width: calc(100% - 30px);
        margin-top: 10px;
        margin-bottom: 10px;

        height: 80px;
    }

    .contact-message-area .contact-grouping{
        display: block;
    }
    
    .contact-message-area .message-input-line{ width: calc(100% - 30px); }
}