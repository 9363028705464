.game-frame{
    flex-basis: 470px;
    min-width: 470px;
    height: 280px;

    flex-grow: 0;

    display: inline-block;

    position: relative;

    box-sizing: border-box;
    border: 2px solid #AAA;

    margin:10px;
}

.game-mouse-over-info{
    position: absolute;
    top: 0px;
    left: 0px;

    visibility: visible;
    opacity: 0.0;

    width: calc(100% - 20px);
    height: calc(100% - 20px);

    box-sizing: border-box;

    margin: 10px;
    padding: 10px;

    border: 1px solid #FFF;
    background-color: rgba(0,0,0,0.8);

    -webkit-transition:opacity 0.25s;
    transition:opacity 0.25s;
}

.game-mouse-over-info:hover{
    opacity: 1.0;
}

.game-frame .game-title{
    font-size: 16px;
    font-weight: bold;
}

.games-content-section .game-mouse-over-info p{
    font-size: 14px;
    font-weight: normal;
    color: #FFF;

    text-align: left;
}

.games-content-section .figure{
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0px;
    left: 0px;

    margin: 0;
    padding: 0;
}

.games-content-section .game-thumbnail{
    width: 100%;
    height: 100%;
    
    object-fit: cover;
}

.game-frame .game-more-info-button{
    position: absolute;

    width: 100%;
    height: 50px;
    line-height: 50px;

    font-size: 18px;
    font-weight: bold;

    box-sizing: border-box;
    border-top: 1px solid #FFF;

    left: 0px;
    bottom: 0px;

    color: #FFF;
    background-color: rgba(0,0,0,0.5);

    text-align: center;

    -webkit-transition:background-color 0.25s, color 0.25s;
    transition:background-color 0.25s, color 0.25s;
}

.game-frame .game-more-info-button:visited{
    color: #FFF;
    background-color: rgba(0,0,0,0.5);
}

.game-frame .game-more-info-button:link{
    /* color: #42B2D2; */
    color: #FFF;
    background-color: rgba(0,0,0,0.5);
}

.game-frame .game-more-info-button:hover{
    color: #000;
    background-color: rgba(255,255,255,0.9);
}

@media only screen and (max-width: 1150px) {
    /* 860 */
    .game-frame{
        flex-basis: 410px;
        min-width: 410px;
        height: 260px;
    
        margin:5px;
    }
}
@media only screen and (max-width: 900px) {
    /* 580 */
    .game-frame{
        flex-grow: 1;
    
        margin:5px;
    }
}
@media only screen and (max-width: 600px) {
    /* 250 min */
    .game-frame{
        flex-basis: 250px;
        min-width: 250px;
        height: 225px;
    
        margin:5px;
    }
} 