/** Site wide styles **/
body{
    padding: 0;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    color: #FFF;

    overflow-x: hidden;
    background: #1D211F;
}

[role=button]{
    cursor:pointer;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

.container{
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;

    height: 100vh;
}

.vertical-fill{
    width: 100%;
    min-height: 1px;
    flex-grow: 1;
}

a {
    text-decoration: none;
    color: #EF452C;
}

a:hover{
    color: #FF835D;
}

/* General site wide styles */
.text-cold { color: #DFFDFD; }
.text-warm { color: #F5E9D4; }

.border-cold { border-color: #DFFDFD; }
.border-warm { border-color: #F5E9D4; }

.background-cold { background-color: #DFFDFD; }
.background-warm { background-color: #F5E9D4; }

input:focus,
textarea:focus,
select:focus{
    outline: none !important;
}

/* Trying to get the dropdown selector to look the same accross all browsers */
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0px;
}

/* 
@media only screen and (max-width: 1150px) {
    
}
@media only screen and (max-width: 900px) {
    
}
@media only screen and (max-width: 600px) {
    
} 
*/
