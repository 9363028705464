.dev-log-content-section>.log-heading{
    font-size: 40px;
    line-height: 45px;
    font-weight: bold;
    text-align: center;

    margin-top: 30px;
    margin-bottom: 75px;
}

.dev-log-content-section>.log-number{
    font-size: 18px;
    font-style: italic;

    color: #EEE;
    margin-bottom: 15px;

    text-align: center;
}

.dev-log-content-section>.log-date{
    margin: 30px 0px 50px 0px;

    font-size: 16px;
    font-style: italic;

    text-align: right;
}

.dev-log-content-section>.log-back{
    width: 100%;

    text-align: left;
    font-size: 20px;

    margin: 0px 0px 50px 20px;
}

.dev-log-content-section h2{
    font-size: 30px;
    line-height: 35px;
    margin-top: 50px;
}

.dev-log-content-section p{
    margin-left: 20px;
}

.dev-log-content-section figure{
    margin: 0px;
    width: 100%;

    padding-left: 20px;
    box-sizing: border-box;
}

.dev-log-content-section figure img{
    object-fit: contain;
    width: 100%;
}

.dev-log-content-section figcaption{
    font-size: 15px;
    box-sizing: border-box;
    padding-left: 25px;

    color: #D8D8E0;
}