.contact-content-section{
    margin-top: 30px;
}

.contact-background-bar{
    position: absolute;

    top: 270px;

    width: 100%;
    height: 250px;

    background-color: #EF452C;
}

@media only screen and (max-width: 600px) {
    .contact-content-section{ margin-top: 230px; }
}