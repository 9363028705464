.banner-image{
    object-fit: cover;
    width: 100%;
    height: 100vh;

    display: block;

    position: fixed;
    top: 0px;
    left: 0px;
    z-index: -50;
}

@media only screen and (max-width: 1150px) {
    .banner-image{
        height: 800px;
    }
}