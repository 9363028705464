.game-wall{
    max-width: 100%;

    margin: 40px auto 50px auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}