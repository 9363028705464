.neon-nexus-content-section>.neon-nexus-heading{
    font-size: 40px;
    line-height: 45px;
    font-weight: bold;
    text-align: center;

    margin-top: 30px;
    margin-bottom: 75px;
}

.neon-nexus-content-section h2{
    font-size: 30px;
    line-height: 35px;
    margin-top: 50px;
}

.neon-nexus-content-section p{
    margin-left: 20px;
}

.neon-nexus-content-section .wp-block-gallery{
    width: 100%;
}

.neon-nexus-content-section .wp-block-gallery .blocks-gallery-grid{
    width: 100%;

    display: block;
    margin: 0;
    padding: 0;

    list-style: none;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding-left: 10px;
}

.neon-nexus-content-section .wp-block-gallery .blocks-gallery-grid .blocks-gallery-item{
    position: relative;

    flex-basis: 250px;
    min-width: 250px;
    height: auto;

    flex-grow: 1;

    padding: 0;
    margin: 10px;

    display: inline-block;
}

.neon-nexus-content-section figure{
    padding: 0;
    margin: 0;
}

.neon-nexus-content-section .wp-block-gallery .blocks-gallery-grid .blocks-gallery-item figure{
    width: 100%;
    height: 100%;
}

.neon-nexus-content-section .wp-block-gallery .blocks-gallery-grid .blocks-gallery-item figure img{
    width: 100%;
    height: 100%;

    object-fit: cover;

    box-sizing: border-box;
    border: 1px solid gray;
}