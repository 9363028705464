.general-content,
.general-content-no-banner{
    display: flex;
    flex-direction: column;

    width: 100%;
}

.general-content-no-banner{
    background-color: #1D211F;
}

.general-content{
    /* 
     * 550 for this margin and 200 for the navigation height 
     * This way we ensure that the entire screen is always covered, even if there is no content
    */
    margin: 550px 0px 0px 0px;
    min-height: calc(100vh - 550px - 200px);

    background-color: #0B0C0C;
}

.general-content-no-banner{
    /* 
     * 200 for the navigation height 
     * This way we ensure that the entire screen is always covered, even if there is no content
    */
    margin: 0;
    min-height: calc(100vh - 200px);
}

.general-content-display{
    position: relative;

    width: 1000px;

    margin: 0px auto;
}

.general-content-display h2 
{
    font-size: 40px; 
    line-height: 45px;
}
.general-content-display p { font-size: 18px; }

.youtube-video{
    display: block;
    margin: 0 auto;

    width: 980px;
    height: 550px;
}

.steam-widget,
.discord-widget{
    display: block;
    margin: 0 auto;
    
    width: 980px;

    margin-bottom: 25px;
}

@media only screen and (max-width: 1150px) {
    .general-content-display{
        width: 860px;
    }

    .youtube-video{
        width: 860px;
        height: 483px;
    }

    .steam-widget,
    .discord-widget{
        width: 860px;
    }
}
@media only screen and (max-width: 900px) {
    .general-content-display{
        width: 580px;
    }

    .youtube-video{
        width: 580px;
        height: 326px;
    }

    .steam-widget,
    .discord-widget{
        width: 580px;
    }
}
@media only screen and (max-width: 600px) {
    .general-content-display{
        width: 90%;
        min-width: 250px;
    }

    .youtube-video{
        width: 100%;
        height: 300px;
    }

    .steam-widget,
    .discord-widget{
        width: 100%;
    }

    .general-content{
        /* Adding the navigation's 200 pixel height to the margin as the navigation is 
            given an absolute position for mobile. */
        margin: 750px 0px 0px 0px;
        min-height: calc(100vh - 550px - 200px);
    }
} 