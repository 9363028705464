.log-button-container{
    position: relative;

    width: 100%;
    height: 140px;

    margin: 10px 0px;
    padding: 5px;

    box-sizing: border-box;

    background-color: rgba(255.0, 255.0, 255.0, 0.0);

    -webkit-transition: background-color 0.25s;
    transition: background-color 0.25s;
}

.log-button-container:hover{
    background-color: rgba(255.0, 255.0, 255.0, 0.2);
}

.log-game-icon{
    position: relative;

    display: block;

    width: 200px;
    height: 130px;

    border: solid #FFF 1px;

    box-sizing: border-box;
}

.log-game-icon>img{
    width: 100%;
    height: 100%;

    margin: 0;

    object-fit: cover;
}

.log-info{
    position: absolute;

    display: block;

    height: 100%;
    width: calc(100% - 210px);

    left: 210px;
    top: 0px;

    box-sizing: border-box;
    padding: 10px;

    color: #FFF;
}

.log-info>.log-title{
    color: #EF452C;
    font-weight: bold;
    margin-bottom: 10px;

    font-size: 18px;
}

.log-info>.log-excerpt p{
    color: #A4ACA8;
    padding: 0;
    margin-top: 5px;

    font-weight: normal;

    font-size: 18px;
}

.log-info>.log-date{
    color: #808F87;
    position: absolute;

    bottom: 5px;
    right: 10px;

    font-style: italic;
    font-size: 16px;
}

.log-separator
{
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
}


@media only screen and (max-width: 1150px) {
    .log-button-container{
        height: 180px;
    }
    .log-game-icon{
        width: 200px;
        height: 170px;
    }
    .log-info{
        width: calc(100% - 210px);
        left: 210px;
    }
}
@media only screen and (max-width: 900px) {
    .log-button-container{
        height: auto;
    }
    .log-game-icon{
        /* visibility: hidden;
        width: 0;
        height: 0; */
        width: 100%;
        height: 150px;
    }
    .log-info{
        position: relative;
        left: 0px;
        top: 0px;

        height: auto;
        width: 100%;
    }
    .log-excerpt{
        visibility: hidden;
        height: 0px;
    }
}
@media only screen and (max-width: 600px) {
    .log-info{
        height: auto;
        box-sizing: border-box;
        padding-bottom: 20px;
    }
} 
